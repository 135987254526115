@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

.newItem {
  background-color: #45ff9f;
  color: #000000;
  position: absolute;
  top: 0px;
  right: 0px;
  border-bottom-left-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 12px;
}
